<template>
    <div class="container">
      <top-header />
       <div class="p-2">
      <ejs-grid ref='grid' id='Grid' :dataSource="data" :allowPaging="true" :pageSettings='pageSettings' :toolbar='toolbarOptions'  :allowExcelExport='true' :toolbarClick='toolbarClick'>      
      <e-columns>
        <e-column field='UID' headerText='Race Id:' textAlign='Center' width=50></e-column>
        <e-column field='First_Name' headerText='First Name:' textAlign='Left' width=50></e-column>
        <e-column field='Last_Name' headerText='Last Name:' textAlign='Left' format='C2' width=70></e-column>
        <e-column field='Email' headerText='Email:' textAlign='Left' format='C2' width=100></e-column>
        <e-column field='Mobile_No' headerText='Mobile:' textAlign='Right' format='C2' width=90></e-column>
        <e-column field='Event' headerText='Event:' textAlign='Right' format='C2' width=90></e-column>
        <e-column field='Date_Received' headerText='Date Received:' textAlign='Right' format='C2' width=90></e-column>
        <e-column field='Address_1' headerText='Address 1' :visible='false' width=150></e-column>
        <e-column field='Address_2' headerText='Address 2' :visible='false' width=150></e-column>
        <e-column field='Address_3' headerText='Address 3' :visible='false' width=150></e-column>
        <e-column field='Town' headerText='Town' :visible='false' width=150></e-column>
        <e-column field='County' headerText='County' :visible='false' width=150></e-column>
        <e-column field='Post_Code' headerText='Post Code' :visible='false' width=150></e-column>
        <e-column field='Cq_or_BT' headerText='Cq or BT' :visible='false' width=150></e-column>
        <e-column field='Paid' headerText='Paid' :visible='false' width=150></e-column>
        <e-column field='DOB' headerText='Date of Birth' :visible='false' width=150></e-column>
        <e-column field='over18' headerText='Over 18' :visible='false' width=150></e-column>
        <e-column field='Emergency_Contact' headerText='Emergency Contact' :visible='false' width=150></e-column>
        <e-column field='emergencyRelationship' headerText='emergency Relationship' :visible='false' width=150></e-column>
        <e-column field='Tel_No_Emergency_No' headerText='Emergency Tel' :visible='false' width=150></e-column>
        <e-column field='strComments' headerText='Comments' :visible='false' width=150></e-column>
        <e-column field='strDietry' headerText='Dietry' :visible='false' width=150></e-column>
        <e-column field='strMealChoice' headerText='Meal Choice' :visible='false' width=150></e-column>

      </e-columns>
    </ejs-grid>
  </div>
</div>

</template>

<script>
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Toolbar, ExcelExport } from "@syncfusion/ej2-vue-grids";
import TopHeader from './topHeader.vue'
import {RepositoryFactory} from '../api/RepositoryFactory'
const RepoPost = RepositoryFactory.post('registration')
//const RepoGet = RepositoryFactory.get('registration')
export default {
components: {
  'ejs-grid': GridComponent,
    'e-column': ColumnDirective,
    'e-columns': ColumnsDirective,
    TopHeader
},
data () {
  return {
    toolbarOptions: ['ExcelExport'],
    data: null,
    pageSettings: { pageSize: 10 }
  }
}, methods: {
  toolbarClick: function(args) {
        if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        let excelExportProperties = {
                dataSource: this.data,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        }
      },
  async getEntries () {

    let formData = new FormData()

formData.append("appKey", this.$store.state.appKey)
formData.append("secToken", this.$store.state.secToken )

    try {
      await RepoPost.getEntries(formData).then(response =>
        {   
          console.log(response)  
          this.data = response.data
         }
       )
    } catch (error) {
      console.log(error)
    }
  }
}, 
mounted () {
  this.getEntries()
},
provide: {
    grid: [Page,Toolbar, ExcelExport]
  }
}
</script>

<style scoped>
  #exportAll{
      margin-left: 20px
  }
  
  .flex-container {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .flex-container>div {
    width: 100%;
    margin: 10px;
    line-height: 75px;
    font-size: 30px;
  }
  
  .flex-item-left
  {
    text-align: left;
  }
  
  .flex-item-right
  {
    text-align: right;
  }
  </style>